require('./bootstrap');

require('./big-picture');

$(document).ready(function(){
    $(".hamburger").click(function(){
        $(this).toggleClass("is-active");
        $('#nav-links').toggleClass("show");
        $('#mainnav').toggleClass("menu-open");
        $('#bg-overlay').toggleClass("show");
    });
    $("#bg-overlay").click(function(){
        $(this).removeClass("show");
        $('#nav-links').removeClass("show");
        $('.hamburger').removeClass("is-active");
        $('#mainnav').removeClass("menu-open");
    });
});
